<template>
  <div class="kefu">
    <div v-if="!isRequest" class="kefu-warp">
      <div class="navBar">
        <div @click="$router.go(-1)" class="back">
          <svg-icon class="leftBtn" iconClass="back"></svg-icon>
          <span>返回</span>
        </div>
        <div class="title">我的客服</div>
        <div class="rightBtn"></div>
      </div>
      <div class="mianBox" v-if="kefuUrl">
        <iframe class="iframe" :src="kefuUrl" frameborder="0"></iframe>
      </div>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import { getService } from '@/api/user';
import Loading from '@/components/Loading';

export default {
  data() {
    return {
      kefuUrl: '',
      isRequest: false,
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getService();
  },
  methods: {
    async getService() {
      this.isRequest = true;
      try {
        let res = await this.$Api(getService);
        this.isRequest = false;
        if (res.code == 200) {
          let baseUrl = this.$store.getters.baseUrl;
          let url = baseUrl.slice(0, baseUrl.length - 1);
          this.kefuUrl = url + res.data;
        }
      } catch (e) {
        this.isRequest = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.kefu {
  height: 100%;

  .kefu-warp {
    height: 100%;
  }

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(217, 217, 217);
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }

      span {
        font-size: 18px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .mianBox {
    width: 100%;
    height: calc(100% - 44px);

    .iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
